import { ReactElement, ReactNode, useMemo } from 'react'
import clsx from 'clsx'
import '@web/styles/LoadingIndicator.less'

type LoadingIndicatorPosition = 'top' | 'center' | 'inline'
type LoadingIndicatorSize = 'xsmall' | 'small' | 'large'

interface LoadingIndicatorChildrenProps {
  loading: boolean
  containerClass?: string
  children: ReactNode
  disableContent: boolean
}

/*
 * Children display for loading indicator
 */
function LoadingIndicatorChildren ({ loading, containerClass, children, disableContent }: LoadingIndicatorChildrenProps): ReactElement {
  return (
    <div
      className={clsx(
        loading && disableContent && 'whiteWash u-disable',
        containerClass
      )}
    >
      {children}
    </div>
  )
}

interface LoadingIndicatorSpinnerProps {
  position?: LoadingIndicatorPosition
  size?: LoadingIndicatorSize
}

/*
 * Spinner for loading indicator
 */
function LoadingIndicatorSpinner ({ position, size }: LoadingIndicatorSpinnerProps): ReactElement {
  return (
    <div
      className={clsx(
        'loading-indicator',
        position === 'center' && 'loading-indicator-center',
        position === 'inline' && 'loading-indidcator-inline'
      )}
    >
      <div className={clsx('preloader-wrapper active', size)}>
        <div className='spinner-layer'>
          <div className='circle-clipper left'>
            <div className='circle' />
          </div>
          <div className='gap-patch'>
            <div className='circle' />
          </div>
          <div className='circle-clipper right'>
            <div className='circle' />
          </div>
        </div>
      </div>
    </div>
  )
}

interface LoadingIndicatorProps {
  position?: LoadingIndicatorPosition
  loading?: boolean
  className?: string
  containerClass?: string
  size?: LoadingIndicatorSize
  children?: ReactNode
  disableContent?: boolean
}

/*
 * Shows a loading indicator while content is loading
 */
function LoadingIndicator ({
  position,
  loading = true,
  className,
  containerClass,
  size,
  children,
  disableContent = true
}: LoadingIndicatorProps): ReactElement {
  const classes = useMemo(() => clsx('react-loading-indicator', className), [className])

  return (
    <div className={classes}>
      {loading && <LoadingIndicatorSpinner position={position} size={size} />}

      {children != null && (
        <LoadingIndicatorChildren loading={loading} containerClass={containerClass} disableContent={disableContent}>
          {children}
        </LoadingIndicatorChildren>
      )}
    </div>
  )
}

export {
  LoadingIndicator,
  LoadingIndicatorSpinner,
  LoadingIndicatorChildren
}
