import { trackEcommerceEvent } from '@web/shared-util-google-analytics'
import { ReactElement, useEffect } from 'react'
import { Link } from 'react-router-dom'

interface NoProductResultsProps {
  query: string
  correctedQuery: string
  articlesCount: number
  isBrowseish: boolean
}

/**
 * This function will return a react element containing the 'No Results' card to be shown when no products are found
 */
function NoProductResults ({ query, correctedQuery, articlesCount, isBrowseish }: NoProductResultsProps): ReactElement {
  // track zero results
  useEffect(() => {
    trackEcommerceEvent('view_item_list', [])
  }, [query])

  return (
    <div className='border c-border-gray u-border-radius-small p-3'>
      {isBrowseish
        ? (
          <>
            <div>No results were found for your filter selections </div>
            <div className='pt-3'>Suggestions:
              <ul>
                <li>Remove some filters to broaden your search.</li>
                <li>Make additional product filter selections.</li>
                <li>Clear all filters to view every available product.</li>
              </ul>
            </div>
          </>
          )
        : (
          <>
            <div>No results were found for your search - "{query}"</div>
            <div className='pt-3'>Suggestions:
              <ul>
                {correctedQuery !== '' &&
                  <li>Try searching for <a href={`/search/results/?query=${correctedQuery}`}>{correctedQuery}</a> instead.</li>}
                {articlesCount > 0 &&
                  <li className='hideInShowCase'><Link to={`/search/results/articles?query=${query}`}>Search for <span className='bold'>"{query}"</span> in <span className='bold'>Articles</span> instead</Link></li>}
                <li>Make sure all words are spelled correctly.</li>
                <li>Try different words.</li>
                <li>Try more general words.</li>
                <li>Try fewer words.</li>
              </ul>
            </div>
          </>
          )}
    </div>
  )
}

export { NoProductResults }
