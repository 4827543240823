import { ReactElement, useMemo } from 'react'
import { GetContextMinimalQuery } from '@web/shared-data-access-queries'
import {
  New,
  BestSeller,
  ThreeC,
  LabGrown,
  Deal,
  NewDeal,
  Machined,
  DieStruck,
  MetalMold
  , ProductStateType
} from '@web/products'
import '@web/styles/ProductCardBadgeSection.less'
import { IProductResult } from './ProductResultCard'
import { useUserContext } from '@web/shared-data-access-context'

interface ProductCardBadgeSectionProps{
  productResult: IProductResult
  searchSourceIsDeals: boolean
}

/**
 * encapsulates logic for whether or not we should show manufacturing badges
 */
function showManufacturingBadge (productResult: IProductResult): boolean {
  return productResult.ProductState !== ProductStateType.Set
}

/**
 * returns a key used for the individual badge components
 */
function getBadgeKey (productResult: IProductResult, badgeName: string): string {
  return `${productResult.Id}-${badgeName}`
}

type GetBadgeFunc = (productResult: IProductResult, searchSourceIsDeals: boolean, context: GetContextMinimalQuery['context'] | undefined) => ReactElement | null

const getNewBadge: GetBadgeFunc = function (productResult, searchSourceIsDeals) {
  if (!searchSourceIsDeals && productResult.IsValidForNewItemBadge === true) {
    return <New key={getBadgeKey(productResult, 'new')} className='mr-1' />
  }
  return null
}
const getBestSellerBadge: GetBadgeFunc = function (productResult, searchSourceIsDeals) {
  if (!searchSourceIsDeals && productResult.IsValidForNewItemBadge === false && productResult.IsValidForBestSellerBadge === true) {
    return <BestSeller key={getBadgeKey(productResult, 'best-seller')} className='mr-1' />
  }
  return null
}
const get3cBadge: GetBadgeFunc = function (productResult, _, context) {
  if (productResult.IsValidFor3CBadge === true && context?.IsInShowcase === false) {
    return <ThreeC key={getBadgeKey(productResult, '3c')} className='mr-1' />
  }
  return null
}
const getMetalMoldBadge: GetBadgeFunc = function (productResult, _, context) {
  if (showManufacturingBadge(productResult) && productResult.IsValidForMetalMoldBadge === true && context?.IsInShowcase === false) {
    return <MetalMold key={getBadgeKey(productResult, 'metal-mold')} className='mr-1' />
  }
  return null
}
const getDieStruckBadge: GetBadgeFunc = function (productResult, _, context) {
  if (showManufacturingBadge(productResult) && (productResult.IsValidForDieStruckBadge === true || productResult.IsValidForCoinedBadge === true) && context?.IsInShowcase === false) {
    return <DieStruck key={getBadgeKey(productResult, 'die-struck')} className='mr-1' />
  }
  return null
}
const getMachinedBadge: GetBadgeFunc = function (productResult, _, context) {
  if (showManufacturingBadge(productResult) && productResult.IsValidForMachinedBadge === true && context?.IsInShowcase === false) {
    return <Machined key={getBadgeKey(productResult, 'machined')} className='mr-1' />
  }
  return null
}
const getDealsBadge: GetBadgeFunc = function (productResult, searchSourceIsDeals, context) {
  if (productResult.IsDiscounted === true && context?.IsInShowcase === false && (searchSourceIsDeals ? productResult.IsValidForNewDealBadge === false : true)) {
    return <Deal key={getBadgeKey(productResult, 'deal')} className='mr-1' />
  }
  return null
}
const getNewDealBadge: GetBadgeFunc = function (productResult, searchSourceIsDeals, context) {
  if (searchSourceIsDeals && productResult.IsDiscounted === true && context?.IsInShowcase === false && productResult.IsValidForNewDealBadge === true) {
    return <NewDeal key={getBadgeKey(productResult, 'new-deal')} className='mr-1' />
  }
  return null
}
const getLabGrownBadge: GetBadgeFunc = function (productResult, _, context) {
  if (productResult.IsValidForLabGrownBadge === true && context?.IsInShowcase === false) {
    return <LabGrown key={getBadgeKey(productResult, 'lab-grown')} className='mr-1' />
  }
  return null
}

const badgeFuncs = [
  getNewBadge,
  getBestSellerBadge,
  get3cBadge,
  getLabGrownBadge,
  getDieStruckBadge,
  getMetalMoldBadge,
  getMachinedBadge,
  getDealsBadge,
  getNewDealBadge
]

/**
 * Section for displaying up to two badges for display on product results cards
 */
function ProductCardBadgeSection ({ productResult, searchSourceIsDeals }: ProductCardBadgeSectionProps): ReactElement {
  const { context } = useUserContext()
  const badges: ReactElement[] = useMemo(() => {
    const badgeArray: ReactElement[] = []

    for (const getBadge of badgeFuncs) {
      const badge = getBadge(productResult, searchSourceIsDeals, context)
      if (badge != null) {
        badgeArray.push(badge)
        if (badgeArray.length === 2) {
          return badgeArray
        }
      }
    }
    return badgeArray
  }, [productResult, searchSourceIsDeals])

  return (
    <div className='product-card-badge-section text-nowrap mb-2 position-relative' data-test='product-card-badge-section'>
      {badges}
    </div>
  )
}

export { ProductCardBadgeSection }
