import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { CategoryContentBlockCreation, CategoryIndexView, CenterStoneColorOptions, CenterStoneColorWithUrl, InformatioAndVideos, InStockFilter, NoProductResults, ProductResults, RelatedSearchTerms, SearchFilter, SelectedFacets, SideNav, SideNavProps, TopNav }
  from '@web/product-navigation-feature'
import { SideNavigationSection as SideNavigationSectionType, SearchPresentationContext } from '@web/product-navigation-types'
import { SearchScaffoldViewModel, GetSearchNavigationQuery } from '@web/shared-data-access-queries'
import { Alert, Breadcrumbs, Tab, Tabs, CmsContent } from '@web/shared-ui-components'
import clsx from 'clsx'
import { StoneForViewAvailableMountingsHeader } from 'libs/search/feature/StoneForViewAvailableMountingsHeader'
import { ReactElement, useMemo } from 'react'
import { matchPath, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useUserContext } from '@web/shared-data-access-context'
import { ContentBlockLocation } from './types'
import { useNavigationItemTracking } from '@web/product-navigation-hooks'

const INSTOCK_SECTION_TITLE = 'ships today'

interface ProductNavigationInnerProps {
  navProps: GetSearchNavigationQuery['getSearchNavigation']
  scaffold: SearchScaffoldViewModel
}

interface SearchResultTab{
  /**
   * the route for the tab click to use to show the proper content
   */
  route: string
  /**
   * the label for the tab to show
   */
  label: string
  /**
   * the tracking name for the tab
   */
  trackingName: string
}

/* eslint-disable complexity */
function ProductNavigationInner ({ navProps, scaffold }: ProductNavigationInnerProps): ReactElement | null {
  const { context } = useUserContext()
  const sideNavigationProps: SideNavProps = useMemo(() => (
    {
      navigationSections: navProps.NavigationSections,
      bottomNavigationSectionContentId: navProps.BottomNavigationSectionContentId,
      centerStoneColors: navProps.CenterStoneColors,
      clearAllUrl: navProps.ClearAllUrl,
      hideSideNav: navProps.HideSideNav,
      showCenterStoneColors: navProps.ShowCenterStoneColors,
      underCategorySectionContentId: navProps.UnderCategorySectionContentId
    }
  ), [navProps])

  const { trackClickEvent } = useNavigationItemTracking()

  const { pathname, search } = useLocation()
  const allowHtmlHeader = useMemo(() => navProps.PresentationContext === SearchPresentationContext.Browse, [navProps.PresentationContext])
  const clearAllUrl = useMemo(() => navProps.ClearAllUrl ?? '', [navProps.ClearAllUrl])
  const hasResults = useMemo(() => (navProps.SearchResults != null && navProps.SearchResults.length > 0), [navProps.SearchResults])
  const correctedQuery: string = useMemo(() => navProps.CorrectedQuery != null ? navProps.CorrectedQuery : '', [navProps.CorrectedQuery])
  const query = useMemo(() => navProps.Query != null ? navProps.Query : '', [navProps.Query])

  const articlesPathMatch = matchPath(pathname, { path: '*/results/articles' })
  const isArticlesTabActive = useMemo(() => articlesPathMatch != null, [articlesPathMatch])
  const sideNavigationSections = useMemo(() => sideNavigationProps.navigationSections?.filter(x => x.Title.toLocaleLowerCase() !== INSTOCK_SECTION_TITLE), [sideNavigationProps.navigationSections])
  const inStockSection = useMemo(() => sideNavigationProps.navigationSections?.find(x => x.Title != null && x.Title.toLocaleLowerCase() === INSTOCK_SECTION_TITLE && x.NavigationItems.filter(y => y.LinkText.toLocaleLowerCase('yes'))), [sideNavigationProps.navigationSections])
  const originalQuery = useMemo(() => navProps.OriginalQuery != null ? navProps.OriginalQuery : '', [navProps.OriginalQuery])
  const showRelatedSearchTerms = useMemo(() => navProps.RelatedSearchTerms != null && navProps.RelatedSearchTerms.length > 0, [navProps.RelatedSearchTerms])
  const pageHeader = useMemo(() => navProps.PageHeader, [navProps.PageHeader])
  const showHtmlTitle = useMemo(() => allowHtmlHeader && !(context?.IsSterling === true && pageHeader?.toLocaleLowerCase() === 'products'), [context?.IsSterling, pageHeader, allowHtmlHeader])
  const showTextTitle = useMemo(() => !allowHtmlHeader && !(context?.IsSterling === true && pageHeader?.toLocaleLowerCase() === 'products'), [context?.IsSterling, pageHeader, allowHtmlHeader])
  const articlesCount = useMemo(() => navProps.ArticlesCount, [navProps.ArticlesCount])
  const resultsTotalRecords = useMemo(() => navProps.Pager?.TotalRecords ?? 0, [navProps.Pager])
  const { productsPathname, articlesPathname } = useMemo<{productsPathname: string, articlesPathname: string}>(() => ({
    productsPathname: pathname.replace(/\/search\/results\/articles$/, '/search/results').replace(/\/!(search)\/results\/articles$/, '/$0'),
    articlesPathname: `${pathname.replace(/\/results(\/articles)?$/, '')}/results/articles`
  }), [navProps.Query, navProps.Category])

  const tabs = useMemo<SearchResultTab[] | null>(() => {
    if (articlesCount <= 0) return null

    return [
      { route: `${productsPathname}${search}`, label: `Products (${resultsTotalRecords})`, trackingName: 'Products' },
      { route: `${articlesPathname}${search}`, label: `Information & Videos (${articlesCount})`, trackingName: 'Information & Videos' }
    ]
  }, [articlesCount, resultsTotalRecords, pathname, search])
  const showTabs = useMemo(() => tabs != null && (hasResults || articlesPathMatch != null), [hasResults, tabs, articlesPathMatch])
  const isSideNav = sideNavigationProps != null && !navProps.HideSideNav
  const isBrowseish = useMemo(() => [
    SearchPresentationContext.Browse,
    SearchPresentationContext.Deals,
    SearchPresentationContext.SalesEvent,
    SearchPresentationContext.StullerFirstSale
  ].includes(navProps.PresentationContext), [navProps.PresentationContext])
  // Results are an index category if there are no results and no facets set
  // Only setting facets can result in a non index category having no products
  const isIndexCategory = !hasResults && isBrowseish && navProps.FacetValues === null

  const isSalesEventish = useMemo(() => [
    SearchPresentationContext.SalesEvent,
    SearchPresentationContext.StullerFirstSale
  ].includes(navProps.PresentationContext), [navProps.PresentationContext])
  const pageType = useMemo(() => {
    if (navProps.PresentationContext === SearchPresentationContext.Search) {
      return 'search'
    } else if (hasResults) {
      return 'product-listing'
    }

    return 'category-listing'
  }, [hasResults, navProps.PresentationContext])

  return (
    <div className='u-flex-grid-row' data-test='page-container'>
      {navProps.BreadCrumbs != null && navProps.BreadCrumbs.length > 0 &&
        <div className='col-12'>
          <Breadcrumbs crumbs={navProps.BreadCrumbs} allowHtml />
        </div>}

      {navProps.SelectedFacets != null && navProps.SelectedFacets.length > 0 &&
        <div className='col-12' data-test='selected-facets-section'>
          <SelectedFacets facets={navProps.SelectedFacets} clearAllUrl={clearAllUrl} />
        </div>}

      {isSideNav &&
        <div id='sideFilterContainer' className='order-2 order-md-1 u-flex-grid-col-lg-3 u-flex-grid-col-md-4 col-12'>
          <SideNav
            navigationSections={sideNavigationSections}
            bottomNavigationSectionContentId={sideNavigationProps.bottomNavigationSectionContentId}
            centerStoneColors={sideNavigationProps.centerStoneColors}
            showCenterStoneColors={sideNavigationProps.showCenterStoneColors}
            clearAllUrl={sideNavigationProps.clearAllUrl}
            hideSideNav={sideNavigationProps.hideSideNav}
            underCategorySectionContentId={sideNavigationProps.underCategorySectionContentId}
            sideNavIsDisabled={isArticlesTabActive}
          />
        </div>}

      <div
        id='navContent'
        className={clsx('navContent col-12', isSideNav && 'order-1 order-md-2 u-flex-grid-col-lg-9 u-flex-grid-col-md-8')}
      >
        {/* Remove facet check when bug around related search terms is fixed (WEB-13047) */}
        {(showRelatedSearchTerms && navProps.FacetValues === null) &&
          <RelatedSearchTerms terms={navProps.RelatedSearchTerms} originalQuery={originalQuery} />}
        {isSalesEventish && <h1 className='searchResultsForLabel' data-test='search-results-for-label'>Stuller Sales Event</h1>}
        {!isSalesEventish && showHtmlTitle && <h1 className='searchResultsForLabel' dangerouslySetInnerHTML={{ __html: pageHeader }} />}
        {!isSalesEventish && showTextTitle && <h1 className='searchResultsForLabel' data-test='search-results-for-label'>{pageHeader}</h1>}
        {context?.IsSterling === true && <CmsContent contentContainerId={50775} />}
        {navProps.ContentId != null &&
          <div
            className='top_content u-margin-bottom-20'
            data-test='content-carousel'
            data-gtm-click-section={`${pageType}-banner`}
          >
            <CmsContent contentContainerId={navProps.ContentId} />
          </div>}
        {showTabs && <Tabs>{tabs?.map(tab => <Tab key={tab.route} route={tab.route} name={tab.label} onClick={() => trackClickEvent('filter', 'Result Type', 'tab', tab.trackingName)} />)}</Tabs>}
        <Switch>
          {/* this is the "article results section of the inner content" */}
          <Route path='*/results/articles'>
            <InformatioAndVideos />
          </Route>
          {/* This is the "product results" section of the inner content  */}
          <Route path='*'>
            {hasResults
              ? <HasProductResults
                  navProps={navProps}
                  clearAllUrl={clearAllUrl} inStockSection={inStockSection} correctedQuery={correctedQuery}
                  scaffold={scaffold}
                />
              : isIndexCategory
                ? <CategoryIndexView navProps={navProps} />
                : <NoProductResults query={query} correctedQuery={correctedQuery} articlesCount={articlesCount} isBrowseish={isBrowseish} />}
          </Route>
        </Switch>
      </div>
      {!hasResults && !isIndexCategory &&
        <div className='col-12 mt-5 pt-5 order-2'>
          <CmsContent contentContainerName='no-results-rr-1' sectionOptions={{ searchTerm: query }} />
        </div>}
    </div>
  )
}

interface HasProductResultsProps {
  navProps: GetSearchNavigationQuery['getSearchNavigation']
  inStockSection?: SideNavigationSectionType
  clearAllUrl: string
  correctedQuery: string
  scaffold: SearchScaffoldViewModel
}

function HasProductResults ({
  navProps, inStockSection, clearAllUrl, correctedQuery, scaffold
}: HasProductResultsProps): ReactElement {
  const { context } = useUserContext()
  const history = useHistory()
  const queryParams = useMemo(() => new URLSearchParams(history.location.search), [history.location.search])
  const query = useMemo(() => navProps.Query != null ? navProps.Query : '', [navProps.Query])
  const topNavigationSections = useMemo(() => navProps.TopNavigationSections, [navProps.TopNavigationSections])
  const centerStoneColors: CenterStoneColorWithUrl[] = navProps.CenterStoneColors?.map(x => {
    if (x.HexCode !== '') {
      queryParams.set('centerStone', x.HexCode)
    } else {
      queryParams.delete('centerStone')
    }
    return {
      ...x,
      url: `${history.location.pathname}?${queryParams.toString()}`
    }
  }) ?? []
  return (
    <>
      {correctedQuery !== '' &&
        <Alert alertType='warning-low' icon={faQuestionCircle} showBackground className='mb-4' data-test='did-you-mean'>
          Did you mean to search for <a href={`/search/results?query=${correctedQuery}&originalQuery=${query}`} data-test='correct-query-link'><em><b>{correctedQuery}</b></em></a>
        </Alert>}
      {topNavigationSections != null && topNavigationSections.length > 0 &&
        <TopNav topNavigationSections={topNavigationSections} clearAllUrl={clearAllUrl} />}
      {navProps.StoneForViewAvailableMountings != null &&
        <StoneForViewAvailableMountingsHeader {...navProps.StoneForViewAvailableMountings} />}
      {inStockSection != null && context != null &&
        <InStockFilter inStockSection={inStockSection} />}
      {navProps.ShowCenterStoneColors && centerStoneColors.length > 0 &&
        <CenterStoneColorOptions centerStoneColors={centerStoneColors} />}
      {navProps.Pager != null &&
        <SearchFilter
          pageSizeOptions={navProps.PageSizeFilter}
          sortByOptions={navProps.SortByFilter}
          pager={navProps.Pager}
          showPageSize={navProps.ShowPageSize}
          merchandisingMode={navProps.MerchandisingMode}
          categoryId={navProps.CategoryId ?? undefined}
        />}
      <ProductResults scaffold={scaffold} viewModel={navProps} />
      {navProps.AllowNewContentBlockCreation && navProps.BottomNavigationSectionContentId == null &&
        <CategoryContentBlockCreation
          newContentBlockName={navProps.NewContentBlockName}
          categoryId={navProps.CategoryId}
          cmsContentType={navProps.CmsContentType}
          contentBlockLocation={ContentBlockLocation.Bottom}
        />}
      {navProps.BottomNavigationSectionContentId != null && context?.IsSterling === false && !context?.IsInShowcase &&
        <CmsContent contentContainerId={navProps.BottomNavigationSectionContentId} />}
    </>
  )
}

export { ProductNavigationInner }
